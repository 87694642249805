<template>
	<div :class="item?'has-item':''" style="background-color: #eee; width:100%;height:100%; display:flex; flex-direction: column;">
	<div class="fullview" v-if="loading">
		<div class="loading-spacer"></div>
		<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
	</div>
	<Map
		:zoom="zoom"
		:markers="markers"
		:fitness="fitness"
		style="width: 100%; height: 100%; position: relative"
	></Map>
	<ChartWaterHistorySimple 
		ref="chart" 
		v-if="item" 
		:item="item" 
		style="height:450px;"
		@closeChart="closeChart"
	></ChartWaterHistorySimple>
	</div>
</template>

<script>
import Map from "@/components/Map.vue";
import ChartWaterHistorySimple from "@/components/ChartWaterHistorySimple.vue";
import { VueLoading } from 'vue-loading-template'

export default {
	components: {
		Map,
		ChartWaterHistorySimple,
		VueLoading
	},
	computed: {
		markers: function () {
			let data = [];
			// for (const key of Object.keys(this.$store.state.places.summaryData)) {
			for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
				let item = this.$store.state.places.summaryData[i];
				// console.log(item);
				let element = {
					id: item.id,
					name: item.name,
					lat: parseFloat(item.position.lat),
					lng: parseFloat(item.position.lng),
					props: this.$store.getters.getAlertColor(item),
					action: 'do_function'
					,action_event: this.set_item
				};
				data.push(element);
				// console.log(element);
			}
			return data;
		},
	},
	async created() {
		this.loading = true;
		for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
			let item = this.$store.state.places.summaryData[i];
			let index = this.$store.getters.getPlaceIndexById(item.id);
			await this.$store.dispatch("getPlaceParameters", { index: index });
		}
		this.loading = false;
	},
	data() {
		return {
			loading: false,
			zoom: 17,
			fitness: true,
			item: null,
		};
	},
	methods: {
		set_item(id) {
			this.item = this.$store.getters.getPlaceById(id);
		}
		,closeChart() {
			if(this.item != null){
				this.item = null;
			}
		}
		// ,onMapClick(){
		// 	if(!this.preventMapClick){
		// 		this.closeChart()
		// 	}
		// }
		// ,onMapMove(){
		// 	this.preventMapClick = true;
		// }
	}
};
</script>
<style lang="scss">
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.loading-spacer {
  height: 30%;
}
.water-history-simple {
	.header {  
		> div:last-child { 
			display: flex; justify-content: flex-end; 
			button { white-space: nowrap; }
			@media screen and (max-width:600px){
                flex-direction: column-reverse;
				
            }
		}
	}
}
</style>
