<template>
	<div ref="googleMap"></div>
</template>

<script>
// Importing loader module
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
	// Get props from parent component (U can change needed props)
	props: {
		markers: Array,
		center: Object,
		zoom: Number,
		fitness: Boolean,
	},
	data() {
		return {
			// Declarate global variable
			google: null,
			map: null,
			apiKey: "AIzaSyDQNm0sg6OhKh6eZvlGhwdIuUl4EYxoQlk",
			mapConfig: {
				// Set lat and lng from props (Important option)
				center: this.center,
				zoom: this.zoom,
				zoomControl: false,
				scaleControl: true,
				scrollwheel: true,
				streetViewControl: false,
				mapTypeId: "roadmap", //"satellite",
				fullscreenControl: true,
				// Google Map Styles: https://mapstyle.withgoogle.com/
				styles: [
					{
						featureType: "water",
						stylers: [
							{
								saturation: 43,
							},
							{
								lightness: -11,
							},
							{
								hue: "#0088ff",
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry.fill",
						stylers: [
							{
								hue: "#ff0000",
							},
							{
								saturation: -100,
							},
							{
								lightness: 99,
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#808080",
							},
							{
								lightness: 54,
							},
						],
					},
					{
						featureType: "landscape.man_made",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ece2d9",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ccdca1",
							},
						],
					},
					{
						featureType: "road",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#767676",
							},
						],
					},
					{
						featureType: "road",
						elementType: "labels.text.stroke",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					},
					{
						featureType: "poi",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "landscape.natural",
						elementType: "geometry.fill",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#EBE5E0",
							},
						],
					},
					{
						featureType: "poi.park",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "poi.sports_complex",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
				],
			},
		};
	},
	// Aysnc is important on API 3.0
	async mounted() {
		// Wait map inited
		const googleMapApi = await GoogleMapsApiLoader({
			apiKey: this.apiKey,
		});
		this.google = googleMapApi;
		this.initializeMap();
	},
	methods: {
		// onMapClick(){
		// 	this.$emit('onMapClick');
		// }
		// ,
		// onMapMove(){
		// 	this.$emit('onMapMove');
		// }
		// ,
		pinSymbol(props, vl) {
			return {
				url: props.marker_url,
				fillColor: props.color,
				fillOpacity: 1,
				scale: 1,
				labelOrigin: new vl.google.maps.Point(28, 28),
			};
		},
		// Map init function (initing in async mounted)
		initializeMap() {
			// Get container from ref in template
			const mapContainer = this.$refs.googleMap;
			// Create new map
			this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
			// Hack to pass the event to an internal function
			const vl = this;
			var bounds = new this.google.maps.LatLngBounds();
			// Get and Set markers and tooltip on a map
			this.markers.forEach(function (mker) {
				// create Markers from props from parent component (see below)
				const velp = new vl.google.maps.Marker({
					position: {
						lat: parseFloat(mker.lat),
						lng: parseFloat(mker.lng),
					},
					map: vl.map,
					title: mker.caption,
					icon: vl.pinSymbol(mker.props, vl),
					label: {
						text: mker.id,
						color: "#000",
						fontFamily: "sans-serif",
						fontWeight: "bold",
						fontSize: "10px",
					},
					action : mker.action
					,action_event : mker.action_event
				});
				//extend the bounds to include each marker's position
				bounds.extend(velp.position);
				// add click open marker event
				velp.addListener("click", function () {
					if(this.action=='do_function'){
						this.map.setCenter(this.position);
						this.action_event(this.label.text)
					} else if(this.action=='go_place'){
						vl.$router
						.push({ name: "Place", params: { id: mker.id } })
						.catch(() => {});
					}
					
					
				});
			});
			if (this.fitness) {
				this.map.fitBounds(bounds, 150);
			}
		},
	},
};
</script>
