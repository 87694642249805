<template>
  <div class="block water-history-simple">
    <div class="header" 
      :style="[
        {
          'background-color': this.$store.getters.getAlertColor(this.item).color
          ,'color': this.$store.getters.getAlertColor(this.item).text_color
        },
      ]"
    >
      <div>
        <span>{{ item.id }}</span>
        <span>{{ item.name }}</span>
        <span>{{ waterLevelFormat(item.waterlevel.level[item.waterlevel.level.length-1])  }}</span>
        <span class="name" :style="'border-color:'+this.$store.getters.getAlertColor(this.item).text_color">
          {{ this.$store.getters.getAlertColor(this.item).name }}
        </span>
      </div>
      <div>
        
        <button @click="handleDataClick(item)">
        データ
          <b-icon icon="arrow-right-circle-fill"></b-icon>
        </button>
        <button class="close" @click="onClickCloseChart()">
          <span>閉じる</span>
          <b-icon icon="x-circle"></b-icon>
        </button>
      </div>
    </div>
    <div class="body" @mousemove="onMouseMove($event, $refs.linechart)">
      <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
      ref="linechart"
    /><div class="overlay"></div>
    </div>
    <div class="footer">
      <div>
      <!--
        <button @click="onClickRange($refs.linechart, 12 )">12時間</button>
        <button @click="onClickRange($refs.linechart, 24 )">1日</button>
        <button @click="onClickRange($refs.linechart, 24*3 )">3日</button>
        <button @click="onClickRange($refs.linechart, 24*7 )">7日</button>
        <button @click="onClickRange($refs.linechart, 24*30 )">30日</button>
      -->
      </div>
      <div>
        <button @click="onClickZoomOut($refs.linechart)">-</button>
        <button @click="onClickZoomIn($refs.linechart)">+</button>
        <button @click="onClickResetZoom($refs.linechart)">リセット</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  TimeScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
  ,zoomPlugin
)

export default {
  name: 'WaterHistoryLineChart',
  components: {
    LineChartGenerator
  }
  ,
  created() {
    // console.log("created") 

	}
  ,beforeMount(){
    // console.log("beforeMount") 
  }
  ,
  mounted() {
	}
  ,computed: {
    chartData:function() {
        return {
          labels: this.item.waterlevel.ts
          ,
          datasets: [
            {
              label: '降雨量'
              ,fill : false
              ,borderColor: "#1560FF"
              ,backgroundColor: "#1560FF"
              ,borderWidth: 2
              ,pointRadius: 0.8
              ,data: this.item.rain.level
              ,yAxisID: 'rain'
            }
            ,
            {
              label: '水位'
              ,fill : "start"
              ,backgroundColor: context => {
                const chart = context.chart
                const { ctx, chartArea, scales } = chart
                if(!chartArea) { return null }
                return this.getGradient(ctx, chartArea, scales)
              }
              ,borderColor: "#3e95cd"
              ,borderWidth: 1
              ,data: this.item.waterlevel.level
              ,yAxisID: 'water_level'
            }
            
          ]
        }
    },
    chartOptions:function() {
      return {
        responsive: true,
        maintainAspectRatio: false
        ,
        layout: {
            padding: {
              top: 20
            }
        }
        ,
        scales: {
          x: {
            ticks: {
              display: true
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              ,callback: function(val) { // , index
                // let num_ticks = this.ticks.length
                let s = ''
                // if(index % (num_ticks-1) === 0 || index % Math.floor(num_ticks / 20) === 0 ){
                let ds = new Date( this.getLabelForValue(val) )
                s = ('0' + ds.getHours()).slice(-2) + ':' + ('0' + ds.getMinutes()).slice(-2); 
                // }
                return s;
              },
              color: 'black',
            }
          }
          ,
          rain: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          water_level: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            min: Math.min(...this.item.waterlevel.level) - 0.1,
            max: Math.max(...this.item.waterlevel.level) + 0.1,
            // max: this.item.alertThresholds[ this.item.alertThresholds.length-1 ] + 0.5
          },
        }
        ,plugins: {
          annotation: { // This is needed to stop error "RangeError: Maximum call stack size exceeded"
              annotations: [

              ]
          },
            tooltip: {
                callbacks: {
                  title: function(context){
                    let title = new Date(context[0].label);
                    return title.getFullYear() + '/' + (title.getMonth()+1) + '/'  +  title.getDate() +' ' + title.getHours() + ':' + title.getMinutes()
                  }
                  ,
                  label: function(context) {
                      let label = context.dataset.label || '';

                      if (label) {
                          label += ' : ';
                      }
                      if (context.parsed.y !== null) {
                          if (context.dataset.label == "降雨量") {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' mm';
                          } else {
                            label += new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' m';
                          }
                      }
                      return label;
                  }
                }
            }
            ,
            legend: {
              display: false
              ,position: 'right'
            }
            ,
            zoom:{
              limits:{
                // x : { min: 20, max: 100 }
              }
              ,
              zoom : {
                wheel : {
                  enabled: true
                  ,speed : 0.01
                  
                }
                ,mode : 'x'
              }
              ,pan : {
                  enabled: true
                  ,mode : 'x'
                }
            }
          
        }
      }
    }
    ,
  }
  ,
  props: {
    chartId: {
      type: String,
      default: 'chart-water-history'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
    // ,
    item: {
      type: Object,
      default: () => []
    }
  },
  data() { 
    return {
      chart:null
      ,
      legend_width: 120
      ,
      
      
        plugins: [
        {
            // 特殊なレジェンドの作成
            id: 'custom_legends',

            beforeDraw: (function (chart) {
              
              try{

                const scales = chart.scales
                const chartArea = chart.chartArea
                const thresholds = this.item.alertThresholds
                const c = this.$store.getters.getColors

                let position ='right'
                let w = this.legend_width
                const h = 50
                let fontsize = 24

                const ww = window.innerWidth
                if(ww <= 440){
                  position = 'top'
                  fontsize = 16
                } else if(ww < 720){
                  position = 'top'
                  fontsize = 22
                }
                let ctx = chart.ctx;
                
                // 単位
                ctx.textAlign = 'left';
                ctx.fillStyle = "#555";
                ctx.font = "normal 12px sans-serif";
                ctx.fillText('水位(m)', chart.width - 45, 12 );
                ctx.restore();

                if (Object.keys(this.item.rain).length) {
                  ctx.textAlign = 'left';
                  ctx.fillStyle = "#555";
                  ctx.font = "normal 12px sans-serif";
                  ctx.fillText('降雨量(mm)', 0, 12 );
                  ctx.restore();
                }
              } catch( e){
                  // console.log(e)
              }
            }).bind(this)
          }
        ]
    }
  }
  ,methods : {
    handleDataClick(item) {
			let index = this.$store.getters.getPlaceIndexById(item.id);
			this.$router
				.push({ name: "Place", params: { id: index, item: item } })
				.catch(() => {});
    },
    onClickRange(e,r){
      console.log(e,r)
    }
    ,
    onClickZoomIn(e){
      let chart = e.getCurrentChart()
      if(chart.getZoomLevel() < 10){
        chart.zoom( 1.2 , 'x')
      }
    }
    ,
    onClickZoomOut(e){
      let chart = e.getCurrentChart()
      chart.zoom( 0.8 , 'x')
    }
    ,
    onClickResetZoom(e){
      let chart = e.getCurrentChart()
      chart.resetZoom()
    }
    ,
    onClickCloseChart(e){
      this.$emit('closeChart');
    }
    ,
    onMouseMove(e, c){
      let chart = c.getCurrentChart()
      let area = chart.chartArea
      if(area.left -10 < e.offsetX && e.offsetX < area.left + area.width +10 && area.top - 10  < e.offsetY && e.offsetY < area.top+area.height + 10){
        document.body.classList.add('can-zoom')
      } else {
        document.body.classList.remove('can-zoom')
      }
    }
    ,
    getGradient(ctx, chartArea, scales){ 
      const thresholds = this.item.alertThresholds
      const c = this.$store.getters.getColors
      const gradientBg = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
      // console.log('--')
      // console.log(scales.x.getValueForPixel( chartArea.left+100 ))
      const v5 =  scales.water_level.getPixelForValue(thresholds[3], 0)
      const v4 =  scales.water_level.getPixelForValue(thresholds[2], 0)
      const v3 =  scales.water_level.getPixelForValue(thresholds[1], 0)
      const v2 =  scales.water_level.getPixelForValue(thresholds[0], 0)
      const v1 =  scales.water_level.getPixelForValue(0, 0)

      // console.log(v1,v2)
      const r5 = ( v5 - chartArea.top) / chartArea.height
      const r4 = ( v4 - chartArea.top) / chartArea.height
      const r3 = ( v3 - chartArea.top) / chartArea.height
      const r2 = ( v2 - chartArea.top) / chartArea.height
      const r1 = ( v1 - chartArea.top) / chartArea.height
      
      if( r1 < 0 ){
        gradientBg.addColorStop(0, c[0].color )
      } else if( r2 < 0 ){
        gradientBg.addColorStop(0, c[1].color )
      } else if( r3 < 0 ){
        gradientBg.addColorStop(0, c[2].color )
      } else if( r4 < 0 ){
        gradientBg.addColorStop(0, c[3].color )
      } else if( r5 < 0 ){
        gradientBg.addColorStop(0, c[4].color )
      } else {
        gradientBg.addColorStop(0, c[5].color )
      }

      if( r5 >= 1 ){
        gradientBg.addColorStop(1, c[5].color )
      } else if( r4 >= 1 ){
        gradientBg.addColorStop(1, c[4].color )
      } else if( r3 >= 1 ){
        gradientBg.addColorStop(1, c[3].color )
      } else if( r2 >= 1 ){
        gradientBg.addColorStop(1, c[2].color )
      } else if( r1 >= 1 ){
        gradientBg.addColorStop(1, c[1].color )
      }  else {
        gradientBg.addColorStop(1, c[0].color )
      }

      if(1 >= r5 && r5 >= 0){
        gradientBg.addColorStop(r5, c[5].color )
        gradientBg.addColorStop(r5, c[4].color )
      }
      if(1 >= r4 && r4 >= 0){
        gradientBg.addColorStop(r4, c[4].color )
        gradientBg.addColorStop(r4, c[3].color )
      }
      if(1 >= r3 && r3 >= 0){
        gradientBg.addColorStop(r3, c[3].color )
        gradientBg.addColorStop(r3, c[2].color )
      }
      if(1 >= r2 && r2 >= 0){
        gradientBg.addColorStop(r2, c[2].color )
        gradientBg.addColorStop(r2, c[1].color )
      }
      if(1 >= r1 && r1 >= 0){
        gradientBg.addColorStop(r1, c[1].color )
        gradientBg.addColorStop(r1, c[0].color )
      }

      return gradientBg
    }
    
  }
}
</script>
<style lang="scss">
.block.water-history-simple {
  margin: 10px;
  .header {
    span { margin-right: .5em; }
    span.name { border:1px solid #fff; display: inline-block; padding: 0 .5em; margin-right:0 !important;  }
    button { color: black; margin-left: 1em; }
  }
  .body { padding-top:10px; }
}
</style>